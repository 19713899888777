/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { FooterBar } from "@src/components/FooterBar";
import Logo from "@src/components/Logo";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { forwardRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink, Outlet, useLocation } from "react-router-dom";

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  backgroundColor?: string;
  title?: string;
}

const TopBar = () => {
  const { pathT, t } = useTranslatedNavigate();

  const menuItems = [
    {
      title: t("SharedStrings.Home"),
      path: pathT("route.account"),
    },
    {
      title: t("SharedStrings.Library"),
      path: pathT("route.library"),
    },
    {
      title: t("SharedStrings.MySessions"),
      path: pathT("route.mySessions"),
    },
    {
      title: t("SharedStrings.Chekins"),
      path: pathT("route.checkins"),
    },
  ];

  const location = useLocation();

  const activeUrl =
    menuItems.find((item) => location.pathname.includes(item.path))?.path ||
    pathT("route.account");

  const [activeItem, setActiveItem] = useState(activeUrl);

  return (
    <div className="h-16 sticky top-0 z-10 bg-white shadow-md mb-8">
      <div className="container max-w-screen-xl mx-auto flex items-center justify-between h-full">
        <div className="flex items-center justify-center h-full">
          <Logo className="w-32 h-32 mt-2 mr-6" showLanguageSpecificIcon />
          {menuItems.map((item) => (
            <RouterLink
              key={item.path}
              to={item.path}
              className={`text-gray-500 font-bold h-full flex items-center px-4 mx-4 ${activeItem === item.path ? "border-solid border-4 border-x-0 border-t-0 border-green-500" : "border-solid border-4 border-x-0 border-t-0 border-transparent"}`}
              onClick={() => setActiveItem(item.path)}
            >
              <p>{item.title}</p>
            </RouterLink>
          ))}
        </div>
        <RouterLink
          to={pathT("route.profile")}
          className="flex items-center justify-center h-full px-4 mx-4"
        >
          <div className="bg-green-500/10 text-green-500 rounded-full p-3 font-bold">
            SR
            {location.pathname}
          </div>
        </RouterLink>
      </div>
    </div>
  );
};

export const BaseLayout = forwardRef<HTMLDivElement, PageProps>(
  ({ title = "", className = "", ...rest }, ref) => {
    return (
      <div
        ref={ref}
        {...rest}
        className={`flex flex-col min-h-screen bg-orange-100 ${className}`}
      >
        <TopBar />
        <main className="flex-grow container mx-auto p-4 md:p-0 md:py-4 max-w-screen-xl">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Outlet />
        </main>
        <FooterBar />
      </div>
    );
  },
);
