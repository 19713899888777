import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { Helmet } from "react-helmet";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { env } from "@src/env";
import routes from "@src/routes";
import theme from "@src/theme";
import GlobalStyles from "@src/theme/GlobalStyles";
import { queryClient } from "@src/utils/queryClient";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { reactPlugin } from "@src/appInsights/AppInsights";
import { setDefaultOptions } from "date-fns";
import { DateFnslocaleMapper } from "@src/DateLocaleSetup";
import { pendoService } from "@src/services/pendo.service";
import { AppInsightsContextProvider } from "@src/appInsights/AppInsightsContext";
import { PendoApiKey } from "@src/utils/constants";
import AxiosSetup from "./AxiosSetup";
import CenteredLoader from "./components/CenteredLoader";
import { getUserProfile, useAuthentication } from "./services/auth.service";
import useTranslatedNavigate from "./services/useTranslateNavigate";

const gtmParams = {
  id: env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

const NON_LOGGED_IN_ROUTES = [
  "route.changeLanguage",
  "route.login",
  "route.twoFactorCodeRequest",
  "route.register",
  "route.register.verify-email",
  "route.welcome-onboarding",
  "route.welcome",
  "route.guest",
];

const App = () => {
  const { isLoggedIn } = useAuthentication();
  const { t } = useTranslatedNavigate();
  const routing = useRoutes(routes(isLoggedIn, t));
  const [password, setPassword] = useState<string>("");
  const [hasDonePreregistrationCheckin, setHasDonePreregistrationCheckin] =
    useState(false);
  const [themesHaveBeenSuggested, setThemesHaveBeenSuggested] = useState(false);

  const location = useLocation();

  const { pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setDefaultOptions({
      locale: DateFnslocaleMapper(),
    });

    const isNonLoggedPage = NON_LOGGED_IN_ROUTES.some((route) =>
      location.pathname.startsWith(pathT(route)),
    );
    if (isNonLoggedPage) {
      setIsLoading(false);
      return;
    }

    // By getting the user profile, we check both refresh and access token validity,
    // and set the isLoggedIn field in redux correctly.
    const checkRefreshTokenValidity = async () => {
      try {
        const userProfile = await getUserProfile();
        if (userProfile) {
          pendoService.initializePendo(
            {
              id: userProfile.id,
              email: userProfile.email,
              employerId: userProfile.employerId,
            },
            PendoApiKey,
          );
        }
      } catch {
        // eslint-disable-next-line no-console
        console.info("The current user is not logged in.");
      } finally {
        setIsLoading(false);
      }
    };

    checkRefreshTokenValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return (
    <GTMProvider state={gtmParams}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ApplicationStateContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values -- old stuff, better not to touch
            value={{
              password,
              setPassword,
              themesHaveBeenSuggested,
              setThemesHaveBeenSuggested,
              hasDonePreregistrationCheckin,
              setHasDonePreregistrationCheckin,
            }}
          >
            <Helmet>
              <meta name="viewport" />
            </Helmet>
            <AxiosSetup navigate={navigate} />
            <GlobalStyles />
            {isLoading ? <CenteredLoader /> : routing}
            <ReactQueryDevtools initialIsOpen={false} />
          </ApplicationStateContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GTMProvider>
  );
};

const Wrapped = () => (
  <AppInsightsErrorBoundary
    appInsights={reactPlugin}
    // eslint-disable-next-line react/no-unstable-nested-components
    onError={(error) => <p className="text-red">{error}</p>}
  >
    <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AppInsightsContextProvider>
        {/* @ts-ignore */}
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </AppInsightsContextProvider>
    </GoogleOAuthProvider>
  </AppInsightsErrorBoundary>
);

export default Wrapped;
