import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClientWithoutSuspense } from "@src/utils/queryClient";
import { BaseLayout } from "@src/layouts/BaseLayouts/BaseLayout";
import { CoursesView } from "@src/views/account/CoursesView";
import CenteredLoader from "@src/components/CenteredLoader";

const LazyLoad = (Component) => (props) => (
  <Suspense fallback={<CenteredLoader />}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...props} />
  </Suspense>
);

const LibraryView = LazyLoad(
  lazy(async () => ({
    default: (await import("@src/views/account/LibraryView")).LibraryView,
  })),
);
const ProfileView = LazyLoad(lazy(() => import("./views/account/ProfileView")));

const NotLoggedInLayout = LazyLoad(
  lazy(() => import("@src/layouts/NotLoggedInLayout")),
);
const DashboardLayout = LazyLoad(
  lazy(() => import("@src/layouts/DashboardLayout")),
);
const ThemeLayout = LazyLoad(lazy(() => import("@src/layouts/ThemeLayout")));
const VideoCallLayout = LazyLoad(
  lazy(() => import("@src/layouts/VideoCallLayout")),
);
const HomeView = LazyLoad(lazy(() => import("@src/views/home/HomeView")));
const LoginView = LazyLoad(lazy(() => import("@src/views/auth/LoginView")));
const NotFoundView = LazyLoad(
  lazy(() => import("@src/views/errors/NotFoundView")),
);
const RegisterView = LazyLoad(
  lazy(() => import("@src/views/auth/RegisterView/RegisterView")),
);
const TwoFactorCodeRequestView = LazyLoad(
  lazy(() => import("@src/views/auth/TwoFactorCodeRequestView")),
);
const VerifyEmailPage = LazyLoad(
  lazy(() => import("@src/views/auth/VerifyEmailPage")),
);
const LinkExpiredView = LazyLoad(
  lazy(() => import("@src/views/auth/LinkExpiredView")),
);
const EmailActivateView = LazyLoad(
  lazy(() => import("@src/views/auth/EmailActivateView")),
);
const DashboardView = LazyLoad(
  lazy(() => import("@src/views/account/DashboardView")),
);
const InvalidActionView = LazyLoad(
  lazy(() => import("@src/views/auth/InvalidActionView")),
);
const ForgotPasswordView = LazyLoad(
  lazy(() => import("@src/views/auth/ForgotPasswordView")),
);
const ForgotPasswordResetView = LazyLoad(
  lazy(() => import("@src/views/auth/ForgotPasswordResetView")),
);
const SentPasswordResetEmail = LazyLoad(
  lazy(() => import("@src/views/auth/SentPasswordResetEmail")),
);
const CheckInsView = LazyLoad(
  lazy(() => import("@src/views/account/CheckInsView")),
);
const MySessionsView = LazyLoad(
  lazy(() => import("@src/views/account/mySessions/MySessionsView")),
);
const SessionReviewView = LazyLoad(
  lazy(() => import("@src/views/account/mySessions/SessionReviewView")),
);
const SessionReviewThanksView = LazyLoad(
  lazy(() => import("@src/views/account/mySessions/SessionReviewThanksView")),
);

const MedicalTripettoSurvey = LazyLoad(
  lazy(() => import("@src/components/TripettoSurvey/MedicalTripettoSurvey")),
);
const GlobalTripettoSurvey = LazyLoad(
  lazy(() => import("@src/components/TripettoSurvey/GlobalTripettoSurvey")),
);
const ThemeTripettoSurvey = LazyLoad(
  lazy(() => import("@src/components/TripettoSurvey/ThemeTripettoSurvey")),
);
const FocusThemesTripettoSurvey = LazyLoad(
  lazy(
    () => import("@src/components/TripettoSurvey/FocusThemesTripettoSurvey"),
  ),
);

const AuthorizedExternalView = LazyLoad(
  lazy(() => import("@src/views/auth/AuthorizedExternalView")),
);
const AuthorizedGuestView = LazyLoad(
  lazy(() => import("@src/views/auth/AuthorizedGuestView")),
);
const ConfirmConsultView = LazyLoad(
  lazy(() => import("@src/views/auth/ConfirmConsultView")),
);
const ArticlesView = LazyLoad(
  lazy(async () => ({
    default: (await import("@src/views/account/ArticlesView")).ArticlesView,
  })),
);

const ThemeView = LazyLoad(lazy(() => import("@src/views/account/ThemeView")));

const BookingView = LazyLoad(
  lazy(() => import("@src/views/booking/BookingView")),
);
const FollowUpFlow = LazyLoad(
  lazy(() => import("@src/views/booking/followUp/FollowUpFlow")),
);
const RescheduleFlow = LazyLoad(
  lazy(() => import("@src/views/booking/reschedule/RescheduleFlow")),
);
const IntroductoryFlow = LazyLoad(
  lazy(() => import("@src/views/booking/introductory/IntroductoryFlow")),
);
const ConfirmSessionFlow = LazyLoad(
  lazy(() => import("@src/views/booking/introductory/ConfirmSessionFlow")),
);
const ConfirmSpaceFlow = LazyLoad(
  lazy(() => import("@src/views/booking/spaces/ConfirmSpaceFlow")),
);
const RebookFlow = LazyLoad(
  lazy(() => import("@src/views/booking/rebook/RebookFlow")),
);
const PersonalLinkView = LazyLoad(
  lazy(() => import("@src/views/booking/personalLink/PersonalLinkView")),
);
const OnboardingLayout = LazyLoad(
  lazy(() => import("@src/layouts/OnboardingLayout")),
);
const FlowLayout = LazyLoad(lazy(() => import("@src/layouts/FlowLayout")));
const BookingCancellationFlow = LazyLoad(
  lazy(() => import("@src/views/booking/cancel/BookingCancellationFlow")),
);
const PersonalFollowUpFlow = LazyLoad(
  lazy(
    () =>
      import("@src/views/booking/followUp/personalLink/PersonalFollowUpFlow"),
  ),
);
const CheckUpResultsView = LazyLoad(
  lazy(() => import("@src/views/account/MedicalCheckInResultsView")),
);
const PostMedicalCheckInFlow = LazyLoad(
  lazy(() => import("@src/views/medical/PostMedicalCheckInFlow")),
);
const MedicalCheckInNotFound = LazyLoad(
  lazy(() => import("@src/views/account/MedicalCheckInNotFound")),
);
const GoToMobileAppStore = LazyLoad(
  lazy(() => import("@src/views/chat/GoToMobileAppStore")),
);
const SessionNotesView = LazyLoad(
  lazy(() => import("@src/views/account/mySessions/SessionNotesView")),
);
const ResilienceCheckinLayout = LazyLoad(
  lazy(() => import("@src/layouts/ResilienceCheckinLayout")),
);
const ResilienceCheckinEntryView = LazyLoad(
  lazy(() => import("@src/views/resilience-checkin/ResilienceCheckEntryView")),
);
const ExplicitConsentWrapper = LazyLoad(
  lazy(() => import("@src/components/ExplicitConsentWrapper")),
);
const SessionContainer = LazyLoad(
  lazy(
    () => import("@src/views/account/mySessions/components/SessionContainer"),
  ),
);
const CancelSpaceFlow = LazyLoad(
  lazy(() => import("@src/views/booking/spaces/CancelSpaceFlow")),
);
const SingleSignOnEmailView = LazyLoad(
  lazy(() => import("@src/views/auth/SingleSignOnView/SingleSignOnEmailView")),
);
const AuthRedirectUri = LazyLoad(
  lazy(() => import("@src/views/auth/SingleSignOnView/AuthRedirectUri")),
);
const LayoutThemePage = LazyLoad(
  lazy(() => import("./layouts/LayoutThemePage")),
);
const OnboardingView = LazyLoad(
  lazy(() => import("./views/onboarding/OnboardingView")),
);
const WrappedLocalizationRedirectView = LazyLoad(
  lazy(() => import("./views/auth/LocalizationRedirectView")),
);
const CourseView = LazyLoad(lazy(() => import("./views/courses/CourseView")));
const CourseLessonView = LazyLoad(
  lazy(() => import("./views/courses/CourseLessonView")),
);
const CreateAccountView = LazyLoad(
  lazy(() => import("./views/auth/CreateAccount/CreateAccountView")),
);
const MakeCall = LazyLoad(
  lazy(() => import("./views/account/MakeCall/MakeCall")),
);
const BasicLayout = LazyLoad(lazy(() => import("./layouts/BasicLayout")));

const routes = (isLoggedIn, t) => {
  const pathT = (key) => `/${t(key)}`;

  /**
   * Routes that are only available when you are logged in
   */
  const protectedRoute = (routeConfig) => {
    if (isLoggedIn) {
      return routeConfig;
    }

    const {
      location: { pathname },
    } = window;

    const redirectURI = `${pathT("route.login")}${
      pathname !== pathT("route.login")
        ? `?redirect=${encodeURIComponent(pathname)}`
        : ""
    }`;

    return {
      path: routeConfig.path,
      element: <Navigate to={redirectURI} />,
      children: routeConfig.children?.map((child) => ({
        path: child.path,
        element: <Navigate to={redirectURI} />,
      })),
    };
  };

  /**
   * Routes that are only available when you are not logged in
   */
  const publicOnlyRoute = (routeConfig) =>
    !isLoggedIn
      ? routeConfig
      : {
          path: routeConfig.path,
          element: <Navigate to={pathT("route.account")} />,
          children: routeConfig.children?.map((child) => ({
            path: child.path,
            element: <Navigate to={pathT("route.account")} />,
          })),
        };

  return [
    {
      path: pathT("route.welcome-onboarding"),
      element: <OnboardingLayout />,
      children: [
        {
          path: "",
          element: <OnboardingView />,
        },
        {
          path: ":step",
          element: <Navigate to={pathT("route.welcome-onboarding")} />,
        },
      ],
    },
    {
      path: "auth/pwc",
      element: <AuthRedirectUri />,
    },
    {
      path: pathT("route.spaces"),
      element: <FlowLayout />,
      children: [
        {
          path: `${t("route.confirmSpace")}`,
          element: <ConfirmSpaceFlow />,
        },
        {
          path: `${t("route.spaceCancellation")}/:spaceId`,
          element: <CancelSpaceFlow />,
        },
      ],
    },
    {
      path: pathT("route.booking"),
      element: <FlowLayout />,
      children: [
        {
          path: "",
          element: <BookingView />,
        },
        {
          path: `${t("route.reschedule")}/:consultId/:step`,
          element: <RescheduleFlow />,
        },
        {
          path: `${t("route.reschedule")}/:consultId`,
          element: <RescheduleFlow />,
        },
        {
          path: `${t("route.cancellation")}/:consultId/:step`,
          element: <BookingCancellationFlow />,
        },
        {
          path: `${t("route.cancellation")}/:consultId`,
          element: <BookingCancellationFlow />,
        },
        {
          path: `${t("route.follow_up")}/:step`,
          element: <FollowUpFlow />,
        },
        {
          path: `${t("route.follow_up")}`,
          element: <FollowUpFlow />,
        },
        {
          path: `${t("route.rebook")}/:rebookConsultId/:step`,
          element: <RebookFlow />,
        },
        {
          path: `${t("route.rebook")}/:rebookConsultId`,
          element: <RebookFlow />,
        },
        {
          path: `${t("route.introductory")}/:step`,
          element: <IntroductoryFlow />,
        },
        {
          path: `${t("route.introductory")}`,
          element: <IntroductoryFlow />,
        },
        {
          path: `${t("route.confirmConsult")}/:token/:email/:step`,
          element: <ConfirmSessionFlow />,
        },
        {
          path: `${t("route.confirmConsult")}/:token/:email`,
          element: <ConfirmSessionFlow />,
        },
        {
          path: ":urlHandle/:step",
          element: <PersonalLinkView />,
        },
        {
          path: ":urlHandle",
          element: <PersonalLinkView />,
        },
        {
          path: `:urlHandle/${t("route.follow_up")}/:step`,
          element: <PersonalFollowUpFlow />,
        },
        {
          path: `:urlHandle/${t("route.follow_up")}`,
          element: <PersonalFollowUpFlow />,
        },
      ],
    },
    {
      path: pathT("route.emailActivate"),
      element: <EmailActivateView />,
    },
    {
      path: pathT("route.authorized"),
      element: <AuthorizedExternalView />,
    },
    {
      path: pathT("route.authorizeGuest"),
      element: <AuthorizedGuestView />,
    },
    {
      path: pathT("route.confirmConsult"),
      element: <ConfirmConsultView />,
    },
    {
      path: "",
      element: <HomeView />,
    },
    {
      path: t("route.changeLanguage"),
      element: <WrappedLocalizationRedirectView />,
    },
    // Login is a special case of publicOnlyRoute, because redirecting is handled manually inside the component
    {
      path: "",
      element: <NotLoggedInLayout />,
      children: [
        { path: t("route.login"), element: <LoginView /> },
        { path: t("route.welcome"), element: <VerifyEmailPage /> },
      ],
    },
    {
      path: pathT("route.createAccount"),
      element: <FlowLayout />,
      children: [
        {
          path: "",
          element: <CreateAccountView />,
        },
        {
          path: ":step",
          element: <CreateAccountView />,
        },
      ],
    },
    {
      path: pathT("route.singleSignOn"),
      element: <OnboardingLayout />,
      children: [
        {
          path: "",
          element: <SingleSignOnEmailView />,
        },
      ],
    },
    {
      path: pathT("route.register"),
      element: <OnboardingLayout isRegister />,
      children: [
        {
          path: "",
          element: <RegisterView />,
        },
        {
          path: ":step",
          element: <Navigate to={pathT("route.register")} />,
        },
      ],
    },
    protectedRoute({
      path: t("route.ResilienceCheckin.Entry"),
      element: <ResilienceCheckinLayout />,
      children: [
        {
          path: "",
          element: <ResilienceCheckinEntryView />,
        },
        {
          path: ":step",
          element: <Navigate to={pathT("route.ResilienceCheckin.Entry")} />,
        },
      ],
    }),
    {
      path: t("route.register.explicit-consent"),
      element: <OnboardingLayout isRegister />,
      children: [
        {
          path: "",
          element: <ExplicitConsentWrapper />,
        },
      ],
    },
    // Add all routes that reuse main layout here, as the '' exact path is already matched with home above
    publicOnlyRoute({
      path: "",
      element: <NotLoggedInLayout />,
      children: [
        // TODO: This should be done as query parameter, not a parameterized route
        { path: `${t("route.login")}/:isFromCheckIn?`, element: <LoginView /> },
        { path: t("route.register"), element: <RegisterView /> },
        {
          path: t("route.twoFactorCodeRequest"),
          element: <TwoFactorCodeRequestView />,
        },
        { path: t("route.forgotPassword"), element: <ForgotPasswordView /> },
        { path: t("route.emailLinkExpired"), element: <LinkExpiredView /> },
        { path: t("route.invalidAction"), element: <InvalidActionView /> },
        {
          path: t("route.emailForgotPassword"),
          element: <ForgotPasswordResetView />,
        },
        {
          path: t("route.sentPasswordReset"),
          element: <SentPasswordResetEmail />,
        },
      ],
    }),
    // Protected MainLayout routes
    protectedRoute({
      path: pathT("route.account"),
      element: <BaseLayout title={t("DashboardView.Title")} />,
      children: [
        {
          path: "",
          element: (
            <QueryClientProvider client={queryClientWithoutSuspense}>
              <DashboardView />
            </QueryClientProvider>
          ),
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.library"),
      element: <BaseLayout title={t("LibraryView.Title")} />,
      children: [
        {
          path: "",
          element: (
            <QueryClientProvider client={queryClientWithoutSuspense}>
              <LibraryView />
            </QueryClientProvider>
          ),
        },
      ],
    }),
    protectedRoute({
      path: `${pathT("route.library")}/${t("route.courses")}`,
      element: <BaseLayout title={t("Content.Title.courses")} />,
      children: [
        {
          path: "",
          element: (
            <QueryClientProvider client={queryClientWithoutSuspense}>
              <CoursesView />
            </QueryClientProvider>
          ),
        },
      ],
    }),
    protectedRoute({
      path: `${pathT("route.library")}/${t("route.articles")}`,
      element: <BaseLayout title={t("Content.Title.articles")} />,
      children: [
        {
          path: "",
          element: (
            <QueryClientProvider client={queryClientWithoutSuspense}>
              <ArticlesView />
            </QueryClientProvider>
          ),
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.profile"),
      element: <BaseLayout title={t("ProfileView.Title")} />,
      children: [
        {
          path: "",
          element: <ProfileView />,
        },
      ],
    }),
    protectedRoute({
      path: `${pathT("route.course")}/:courseId/:lessonId`,
      element: (
        <BasicLayout
          isHeaderVisible={false}
          isFooterVisible={false}
          className="bg-orange-100"
        />
      ),
      children: [
        {
          index: true,
          element: <CourseLessonView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.course"),
      element: <BasicLayout className="bg-orange-100" />,
      children: [
        {
          path: ":courseId",
          element: <CourseView />,
        },
        {
          path: "",
          element: <CourseView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.call"),
      element: <VideoCallLayout />,
      children: [
        {
          path: ":consultId",
          element: <MakeCall />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.mySessions"),
      element: <BaseLayout title={t("MySessionsView.Title")} />,
      children: [
        {
          path: "",
          element: <MySessionsView />,
          children: [
            {
              path: "",
              element: <SessionContainer />,
            },
            {
              path: `${t("route.mySessions.consult")}/:consultId`,
              element: <SessionNotesView />,
            },
          ],
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.mySessions.review"),
      element: <VideoCallLayout />,
      children: [
        {
          path: "",
          element: <SessionReviewView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.mySessions.review.thanks"),
      element: <VideoCallLayout />,
      children: [
        {
          path: "",
          element: <SessionReviewThanksView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.checkins"),
      element: <BaseLayout title={t("CheckinsPage.Title")} />,
      children: [
        {
          path: "",
          element: <CheckInsView />,
        },
      ],
    }),
    {
      path: pathT("route.themes"),
      element: <BaseLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={t("route.account")} />,
        },
        protectedRoute({
          path: ":themeName",
          element: <LayoutThemePage />,
          children: [
            {
              path: "",
              element: <ThemeView />,
            },
            {
              path: ":slug",
              element: <ThemeView />,
            },
          ],
        }),
      ],
    },
    {
      path: pathT("route.themes"),
      element: <ThemeLayout showFooter={false} />,
      children: [
        protectedRoute({
          path: `:themeName/${t("route.checkin")}`,
          element: <ThemeTripettoSurvey />,
        }),
        protectedRoute({
          path: t("route.focusCheckin"),
          element: (
            <FocusThemesTripettoSurvey redirectUri={pathT("route.checkins")} />
          ),
        }),
        protectedRoute({
          path: t("route.checkin"),
          element: (
            <GlobalTripettoSurvey redirectUri={pathT("route.checkins")} />
          ),
        }),
      ],
    },
    {
      path: t("route.guest"),
      element: <DashboardLayout showFooter={false} />,
      children: [
        {
          path: `${t("route.checkin")}/:themeName`,
          element: (
            <ThemeTripettoSurvey
              redirectUriOnNotLoggedIn={pathT("route.createAccount")}
            />
          ),
        },
        {
          path: t("route.checkin"),
          element: (
            <GlobalTripettoSurvey
              redirectUriOnNotLoggedIn={pathT("route.createAccount")}
            />
          ),
        },
      ],
    },
    {
      path: t("route.medical"),
      element: <DashboardLayout isMedical showFooter={false} />,
      children: [
        {
          path: t("route.medical-checkin"),
          element: (
            <MedicalTripettoSurvey
              redirectUriOnNotLoggedIn={`${pathT("route.medical")}/${t(
                "route.medical.checkIn.post",
              )}`}
            />
          ),
        },
      ],
    },
    {
      path: t("route.medical"),
      element: <DashboardLayout isMedical />,
      children: [
        {
          path: t("route.medical-checkin-results"),
          element: <CheckUpResultsView />,
        },
      ],
    },
    {
      path: t("route.medical"),
      element: <FlowLayout isMedical />,
      children: [
        {
          path: `${t("route.medical.checkIn.post")}/:step`,
          element: <PostMedicalCheckInFlow />,
        },
        {
          path: `${t("route.medical.checkIn.post")}`,
          element: <PostMedicalCheckInFlow />,
        },
        {
          path: `${t("route.medical.checkIn.notFound")}/:step`,
          element: <MedicalCheckInNotFound />,
        },
        {
          path: `${t("route.medical.checkIn.notFound")}`,
          element: <MedicalCheckInNotFound />,
        },
      ],
    },
    { path: t("route.chat.invite"), element: <GoToMobileAppStore /> },
    {
      path: "*",
      element: <NotFoundView showNavBar />,
    },
  ];
};

export default routes;
