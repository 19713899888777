import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import axios from "axios";
import { apiPaths } from "@src/utils/constants";
import { ResponseWithPagination } from "@src/models/Pagination";
import { ContentItem } from "@src/models/Course";
import { useTranslation } from "react-i18next";

export interface ContentTypePagination {
  contentType: string;
  currentPage: number;
  total: number;
  limit: number;
  hasNextPage: boolean;
}

export interface ContentLibraryResponse
  extends ResponseWithPagination<ContentItem[]> {
  contentTypePagination: ContentTypePagination[];
}

export enum ContentEventType {
  Click = "Click",
}

export interface ContentEvent {
  contentId: number;
  eventType: ContentEventType;
}

interface UseGetContentsProps {
  language;
  type: string;
  theme: string | null;
  page?: number;
  limit?: number;
  contentTypePagination: ContentTypePagination[] | null;
  baseUrl?: string | undefined;
  contentsPath?: string;
}

interface RequestData {
  language: string;
  theme: string | null;
  contentTypePagination?: ContentTypePagination[] | null;
  type?: string;
}

export const useGetInfiniteContent = ({
  type,
  theme = null,
}: {
  type: "articles" | "courses";
  theme: string | null;
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const contentsPath = apiPaths.contents;
  const limit = 9;

  return useInfiniteQuery<ContentLibraryResponse>(
    [contentsPath, language, type, theme, limit],
    async ({ pageParam = 0 }) => {
      const requestData: RequestData = {
        language,
        theme,
        type, // Explicitly setting type as "articles"
      };

      const response = await axios.post(
        `${contentsPath}?limit=${limit}&page=${pageParam}`,
        requestData,
      );

      return response.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        return pagination.hasNextPage ? pagination.currentPage + 1 : undefined;
      },
    },
  );
};

export const useGetContent = ({ type }: { type: "articles" | "courses" }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const contentsPath = apiPaths.contents;
  const limit = 6;
  const theme = null;

  return useQuery(
    [contentsPath, language, type, theme, limit],
    async () => {
      const response = await axios.post(`${contentsPath}?limit=${limit}`, {
        language,
        type,
        theme,
      });
      return response.data as ContentLibraryResponse;
    },
    { suspense: false },
  );
};

export const useGetContents = ({
  language,
  type,
  theme,
  limit = 16,
  contentTypePagination = null,
  baseUrl = undefined,
  contentsPath = apiPaths.contents,
}: UseGetContentsProps) => {
  try {
    return useInfiniteQuery(
      [contentsPath, language, type, theme, limit],
      async ({ pageParam = 0 }) => {
        try {
          const requestData: RequestData = {
            language,
            theme,
            type,
            contentTypePagination,
          };

          const response = await axios.post(
            `${contentsPath}?limit=${limit}&page=${pageParam}`,
            requestData,
            {
              baseURL: baseUrl,
            },
          );
          return response.data as ContentLibraryResponse;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      {
        getNextPageParam: ({ pagination }) => {
          const nextPage = pagination.hasNextPage
            ? pagination.currentPage + 1
            : undefined;
          return nextPage;
        },
      },
    );
  } catch (error) {
    if (error instanceof Promise) {
      return {
        data: undefined,
        isLoading: true,
        isError: false,
        fetchNextPage: () => {},
        hasNextPage: false,
        isFetchingNextPage: false,
      };
    }

    return {
      data: null,
      isLoading: false,
      isError: true,
      fetchNextPage: () => {},
      hasNextPage: false,
      isFetchingNextPage: false,
    };
  }
};

export const useSaveContentEvent = () =>
  useMutation(async (contentEvent: ContentEvent) => {
    await axios.post(`${apiPaths.contents}/event`, contentEvent);
  });
